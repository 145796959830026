import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { useMixpanel } from "react-mixpanel-browser";
import { RecaptchaVerifier } from "firebase/auth";
import { Button, Dialog, Divider, TextField } from "@mui/material";

import api from "services/api";
import { auth } from "services/firebase";
import { sendMessageWavy, sendMsgWavy, sendTokenFirebase } from "services/loginService";
import { getBusiness } from "services/businessService";

import { AppBar, Chip, Container, Button as GlobalButton, Text } from "components";
import { fireBaseErrors, trimTelephoneNumber } from "utils";

import { isNil } from "lodash";
import { useConfig } from "context/config.context";
import { useLogin } from "context/login.context";

import { getAppName } from "utils/validators";

import useStyles from "./styles-code";

const Code = () => {
	const classes = useStyles();
	const navigate = useNavigate();
	const mixpanel = useMixpanel();
	const dispatch = useDispatch();

	const [timeCount, setTimeCount] = useState(60);
	const [codeDigits, setCodeDigits] = useState(["", "", "", "", "", ""]);
	const [appVerifier, setAppVerifier] = useState(null);
	const [openModal, setOpenModal] = useState(false);
	const [modalMessage, setModalMessage] = useState("");

	const { handleLoading, typeAccess, handleFeedback } = useConfig();
	const { businessState, getBusinessState } = useLogin();

	const [linkWhatsapp, setLinkWhatsapp] = useState('');
 	const [typeAuthVerify, setTypeAuthVerify] = useState('');

	const codeInputRefs = useRef([]);

	const addToRefs = (el) => {
		if (el && !codeInputRefs.current.includes(el)) {
			codeInputRefs.current.push(el);
		}
	};

	const sendCode = async () => {
		if (businessState?.telephoneNumber) {
			try {
				await sendMsgWavy("+" + businessState?.ddi + trimTelephoneNumber(businessState?.telephoneNumber));
				setTimeCount(60);
			} catch (error) {
				setModalMessage(error?.message);
				setOpenModal(true);
			}
		}
	};

	const handleVerificationError = (error) => {
		handleLoading(false);
		const errorMessages = {
			"Request failed with status code 400": "Erro ao validar o telefone - o código fornecido não é válido",
		};
		setModalMessage(errorMessages[error.message] || fireBaseErrors(error.code));
		resetCodeInputs();
		setOpenModal(true);
	};

	const resetCodeInputs = () => {
		setCodeDigits(["", "", "", "", "", ""]);
		if (codeInputRefs.current[0]) {
			codeInputRefs.current[0].focus();
		}
	};

	const verifyCode = useCallback(
		async (code) => {
			try {
				handleLoading(true);
				const phoneNumber = "+" + businessState?.ddi + trimTelephoneNumber(businessState?.telephoneNumber);
				const enteredCode = code || codeDigits.join("");

				if (enteredCode.length === 6) {
					const result = await sendMessageWavy(phoneNumber, enteredCode);
					await sendTokenFirebase(auth, result.data.message);

					mixpanel.identify(phoneNumber);
					mixpanel.track("Login - Web Business");
					mixpanel.track("onb. Inserir código de confirmação - Web Business");

					processUser();
				}
			} catch (error) {
				handleVerificationError(error);
			}
		},
		[codeDigits, dispatch, mixpanel]
	);

	const processUser = async () => {
		try {
			const business = await getBusiness();
			if (business.data) dispatch({ type: "UPDATE_BUSINESS", payload: business.data[0] });

			const res = await api.get(
				"schedule/?is_message_bookings=false&is_payment_types=false&is_message_clients=true&is_sectors=false&is_pricing_type=false"
			);
			const data = res.data;
			// Verificação e navegação para quando não há business e não é um novo negócio
			if (!data?.business && !businessState.isNewBusiness) {
				handleFeedback(
					"Atenção",
					"Não foi possível encontrar o seu negócio. Por favor, crie um novo.",
					"warning",
					5000
				);
				handleLoading(false);
				navigate("/login", {
					state: {
						newBusiness: true,
					},
				});
				return;
			}

			localStorage.setItem("logged", "true");

			// Não possui business e é um novo negócio
			if (!data?.business && businessState?.isNewBusiness) {
				handleLoading(false);
				navigate("/selecionar-setores", {
					state: {
						fullName: businessState?.fullName,
						email: businessState?.email,
						businessName: businessState?.businessName,
						phone: "+" + businessState?.ddi + trimTelephoneNumber(businessState?.telephoneNumber),
					},
				});
				return;
			}

			// Verificação para quando o business está ativo
			if (data?.business?.status === "active") {
				handleLoading(false);
				// Caso possua seja renovação ou onboarding
				if (typeAccess === "onboarding" || typeAccess === "renew") {
					navigate(`/subscription/${typeAccess ?? "onboarding"}`);
				} else {
					navigate("/schedule");
				}
				return;
			}
			// Verificação para assinaturas pendentes ou inativas com expiração nula
			if (
				(data?.subscription?.status === "inactive" || data?.subscription?.status === "pending") &&
				isNil(data?.subscription?.expiration)
			) {
				handleLoading(false);
				navigate("/subscription/onboarding", {
					state: businessState,
				});
				return;
			}
			// Verificação para outros estados da assinatura
			if (
				data?.subscription?.status === "inactive" ||
				data?.subscription?.status === "pending" ||
				data?.subscription?.status === "testing"
			) {
				handleLoading(false);
				navigate(`/subscription/${typeAccess ?? "onboarding"}`, { state: { user: res.data.message } });
			}
		} catch (err) {
			handleLoading(false);
			setOpenModal(true);
			setModalMessage(err.message);
		}
	};

	useEffect(() => {
		if (auth && !appVerifier) {
			const verifier = new RecaptchaVerifier("recaptcha", { size: "invisible" }, auth);
			setAppVerifier(verifier);
			handleLoading(false);
		}
	}, [auth, appVerifier]);

	useEffect(() => {
		if (timeCount > 0) {
			const timer = setTimeout(() => setTimeCount(timeCount - 1), 1000);
			return () => clearTimeout(timer);
		}
	}, [timeCount]);

	const handleAutoFill = (e) => {
		const code = e.target.value;
		if (code.length === 6 && /^\d{6}$/.test(code)) {
			const newCodeDigits = code.split("");
			setCodeDigits(newCodeDigits);
			verifyCode(code);

			if (codeInputRefs.current[5]) {
				codeInputRefs.current[5].focus();
			}
		}
	};

	const handleChange = (e, index) => {
		const value = e.target.value;
		if (/^\d$/.test(value)) {
			const updatedCode = [...codeDigits];
			updatedCode[index] = value;
			setCodeDigits(updatedCode);

			if (index < 5 && codeInputRefs.current[index + 1]) {
				codeInputRefs.current[index + 1].focus();
			}

			if (updatedCode.every((digit) => digit !== "")) {
				verifyCode(updatedCode.join(""));
			}
		}
	};

	const handleKeyPress = (e, index) => {
		if (e.key === "Backspace" || e.key === "Delete") {
			e.preventDefault();
			const updatedCode = [...codeDigits];
			if (updatedCode[index] !== "") {
				updatedCode[index] = "";
				setCodeDigits(updatedCode);
			} else if (index > 0) {
				if (codeInputRefs.current[index - 1]) {
					codeInputRefs.current[index - 1].focus();
					const prevCode = [...codeDigits];
					prevCode[index - 1] = "";
					setCodeDigits(prevCode);
				}
			}
		} else if (e.key === "Enter") {
			if (codeDigits.every((digit) => digit !== "")) {
				handleLoading(true);
				verifyCode();
			}
		}
	};

	const handlePaste = (e) => {
		e.preventDefault();
		const pasteData = e.clipboardData.getData("Text").trim();
		if (/^\d{6}$/.test(pasteData)) {
			const newCodeDigits = pasteData.split("");
			setCodeDigits(newCodeDigits);
			verifyCode(pasteData);

			if (codeInputRefs.current[5]) {
				codeInputRefs.current[5].focus();
			}
		}
	};

	useEffect(() => {
		getBusinessState();
		setLinkWhatsapp(businessState?.infoPhone?.linkWhatsApp ?? '');
    	setTypeAuthVerify(businessState?.infoPhone?.typeAuthVerify ?? "sms");
	}, []);

	return (
		<>
			<Container style={{ gap: "16px" }}>
				<div style={{ display: "flex", alignContent: "center", width: "100%" }}>
					<AppBar progress={businessState?.businessName ? 50 : null} />
					<Chip help={true} customStyle={{ marginTop: "1rem" }} />
				</div>

				<Text style={{ color: "#3E3E3E" }} variant={"mid"}>
					Informe o código de confirmação
				</Text>

				<input
					type="text"
					style={{ position: "absolute", opacity: 0, pointerEvents: "none" }}
					autoComplete="one-time-code"
					onChange={handleAutoFill}
				/>

				<div style={{ width: "100%" }}>
					<div style={{ display: "flex", justifyContent: "center", gap: "8px" }}>
						{codeDigits.map((digit, index) => (
							<TextField
								autoFocus={index === 0 && digit === ""}
								key={index}
								id={`codeDigit${index}`}
								className={classes.field}
								inputProps={{
									style: {
										fontSize: "1.3rem",
										padding: "12px 4px",
										textAlign: "center",
										fontFamily: "Poppins",
									},
									maxLength: 1,
									pattern: "[0-9]*",
									inputMode: "numeric",
								}}
								value={digit}
								onChange={(e) => handleChange(e, index)}
								onKeyDown={(e) => handleKeyPress(e, index)}
								onPaste={handlePaste}
								inputRef={addToRefs}
								variant="outlined"
							/>
						))}
					</div>

             {getAppName() === "Z Doctors" && typeAuthVerify === 'whatsapp' ? (
				<div style={{ marginTop: "1rem" }}>
					<GlobalButton
						title="Clique aqui para receber seu código"
						type="button"
						variant="outlined"
						handleClick={() => window.open(linkWhatsapp, '_blank')}
						
						className="btn-default btn-receive-code-whatsapp"
						fullWidth={true}
					/>
				</div>
             ) : (<div>
				<Text align={"center"} style={{ marginTop: "16px" }}>
					Não recebeu o código?
				</Text>

				<Text
					align={"center"}
					style={{
						color: timeCount === 0 ? "#39ADC4" : "gray",
						cursor: timeCount === 0 ? "pointer" : "default",
						marginTop: "0.5rem",
					}}
					onClick={() => {
						if (timeCount === 0) {
							sendCode();
							setTimeCount(60);
						}
					}}>
					{timeCount === 0 ? "Reenviar código" : `Reenviar em ${timeCount}s`}
				</Text>
				
			</div>	)}			
				
				</div>

				<div style={{ flex: 1 }}></div>
					
				<GlobalButton
					title="Avançar"
					type="button"
					variant="contained"
					handleClick={() => {
						if (codeDigits.every((digit) => digit !== "")) {
							handleLoading(true);
							verifyCode();
						} else {
							setModalMessage("Por favor, preencha todos os dígitos.");
							setOpenModal(true);
						}
					}}
					className="btn-default btn-color-primary"
					fullWidth={true}
				/>
			</Container>

			<Dialog
				open={openModal}
				PaperProps={{
					style: { borderRadius: 20, maxWidth: 400 },
				}}>
				<Text style={{ margin: "1rem" }}>
					<p className={classes.littlePurple}>{modalMessage}</p>
				</Text>
				<Divider />
				<Button
					className={classes.littleBlue}
					style={{ padding: "15px", fontSize: "1rem", fontWeight: "600", fontFamily: "Poppins" }}
					onClick={() => setOpenModal(false)}>
					OK
				</Button>
			</Dialog>

			<div id="recaptcha"></div>
		</>
	);
};

export default Code;
